import React from 'react'
import Layout from '../components/Layout'
import GlobalStyle from '../styles/globalStyles'
import SEO from '../components/SEO'
import SliceZone from '../components/SliceZone'
import { graphql } from 'gatsby'

const Page = ({ data, pageContext }) => {

  const prismicContent = data.allPrismicPage.edges[0]
  if (!prismicContent) return null
  const document = prismicContent.node

  const capitalizeFirstLetter = (input) => {
    return input[0].toUpperCase() + input.slice(1)
  }

  return (
    <Layout>
      <GlobalStyle />
      <SEO title={capitalizeFirstLetter(document.uid)} />
      <SliceZone sliceZone={document.data.body} />
    </Layout>
  )
}

export const query = graphql`
query query($slug: String) {
  allPrismicPage (filter: {uid: { eq: $slug }}) {
      edges {
        node {
          uid
          data {
            body {
              ... on PrismicPageBodyText {
                id
                primary {
                  content {
                    raw
                  }
                }
                slice_type
              }
              ... on PrismicPageBodyQuote {
                id
                primary {
                  quote {
                    text
                  }
                }
                slice_type
              }
              ... on PrismicPageBodyFullWidthImage {
                id
                primary {
                  full_width_image {
                    url
                  }
                }
                slice_type
              }
              ... on PrismicPageBodyImageGallery {
                id
                primary {
                  gallery_title {
                    text
                  }
                }
                items {
                  image {
                    alt
                    url
                  }
                  image_description {
                    text
                  }
                  link {
                    url
                  }
                  link_label {
                    text
                  }
                }
                slice_type
              }
              ... on PrismicPageBodyImageHighlight {
                id
                primary {
                  description {
                    text
                    raw
                  }
                  featured_image {
                    url
                    alt
                  }
                  image_caption {
                    text
                  }
                  link {
                    uid
                    url
                    type
                  }
                  link_label {
                    text
                  }
                  position
                  title {
                    text
                    raw
                  }
                }
                slice_type
              }
            }
          }
        }
      }
    }
  }
  
`

export default Page